<script>
import moment from "moment";
import {CountTo} from "vue3-count-to";
import KpiItem from "@/components/kpis/KpiItem.vue";

export default {
  name: "ResponseTimeKPI",
  data() {
    return {
      showModal: false,
      manager: null,
      inquiry_grade: 'all',
      grade_to_marks: {
        "A": 3,
        "B": 2,
        "C": -1
      },
      kpi_bonus_percentage: 90
    }
  },
  components: {
    CountTo,
    KpiItem
  },
  emits: ['provideManagerData'],
  inject: ['response_time_kpi_bonus_percentage'],
  computed: {
    inquires() {
      let inquires = []

      if (['A', 'B', 'C'].includes(this.inquiry_grade)) {
        inquires = (this.manager.completion.details[this.inquiry_grade] || {}).inquiries || []
      } else {
        inquires = Object.keys(this.manager.completion.details).flatMap(grade =>
            this.manager.completion.details[grade].inquiries
        );
      }

      return inquires
    },
    a_inquires_count() {
      return this.inquires.filter(i => i.completion_grade === 'A').length
    },
    b_inquires_count() {
      return this.inquires.filter(i => i.completion_grade === 'B').length
    },
    c_inquires_count() {
      return this.inquires.filter(i => i.completion_grade === 'C').length
    },
    manager_username() {
      return this.manager.manager
          ? this.manager.manager.full_name.trim().length > 0 ? this.manager.manager.full_name : this.manager.manager.username
          : 'Unknown Manager'
    }
  },
  methods: {
    async openModal(manager, inquiry_grade) {
      this.manager = manager

      if (inquiry_grade) {
        this.inquiry_grade = inquiry_grade
      } else {
        this.inquiry_grade = 'all'
      }

      await this.$router.push({
        query: {
          start_date: this.$route.query.start_date || undefined,
          end_date: this.$route.query.end_date || undefined,
          sales_manager_id: this.manager.manager.id,
          kpi: 'follow_up',
          filter_by_grade: this.inquiry_grade
        }
      })

      this.showModal = true
    },
    async closeModal() {
      if (this.$route.query.sales_manager_id) {
        let query = {...this.$route.query}
        delete query.sales_manager_id
        delete query.kpi
        delete query.filter_by_grade
        await this.$router.push({query: query})
      } else {
        this.showModal = false
      }
    },
    cleanText(html) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText;
    },
    formatDate(date) {
      return moment(date).format('lll')
    },
    calculateDuration(startDate, endDate) {
      const now = moment(endDate); // Get the current time
      const start = moment(startDate); // Parse the start date
      const durationInSeconds = now.diff(start, 'seconds'); // Calculate the difference in seconds

      const duration = moment.duration(durationInSeconds, 'seconds');

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let formattedDuration = '';

      if (days > 0) {
        formattedDuration += `${days} days `;
      }

      formattedDuration += `${hours} hours ${minutes} minutes ${seconds} seconds`;

      return formattedDuration.trim();
    },
    formatDuration(durationInSeconds) {
      const duration = moment.duration(durationInSeconds, 'seconds');

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let formattedDuration = '';

      if (days > 0) {
        formattedDuration += `${days} days `;
      }

      formattedDuration += `${hours} hours ${minutes} minutes ${seconds} seconds`;

      return formattedDuration.trim(); // Trim any extra spaces
    },
    durationInDays(durationInSeconds) {
      const duration = moment.duration(durationInSeconds, 'seconds');

      const totalHours = duration.asHours();
      const days = Math.floor(duration.asDays());

      let daysDescription;

      if (totalHours < 48) {
        daysDescription = "within 2 days";
      } else {
        daysDescription = `after ${days} days`;
      }

      return daysDescription
    }
  },
  watch: {
    "$route.query.sales_manager_id": {
      handler(newValue) {
        if (this.$route.query.kpi === 'follow_up') {
          setTimeout(() => {
            this.showModal = !!newValue;
          }, 500)
        } else {
          this.showModal = false
        }
      },
      immediate: true,
      deep: true
    },
    showModal(newValue) {
      if (!newValue) {
        setTimeout(async () => {
          let query = {...this.$route.query}
          delete query.sales_manager_id
          delete query.kpi
          delete query.filter_by_grade
          await this.$router.push({query: query})
        }, 500)
      }
    }
  },
}
</script>

<template>
  <b-modal v-if="manager" v-model="showModal"
           hide-footer hide-header centered
           fullscreen
  >
    <div class="d-flex align-items-center mb-4 gap-3">
      <div @click="closeModal()" class="flex-shrink-0 avatar-sm cursor-pointer">
        <div class="avatar-title bg-soft-primary text-primary fs-22 rounded">
          <i class="mdi mdi-arrow-left"></i>
        </div>
      </div>
      <div>
        <h4 class="mb-1 text-body fw-medium">
          FOLLOW-UP KPI
        </h4>
        <span class="text-muted">
          By {{ manager_username }}
        </span>
      </div>
    </div>

    <div class="row mb-3" v-if="inquiry_grade === 'all'">
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow-none border mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-light text-primary rounded fs-3 material-shadow">
                  <i class="mdi mdi-chart-timeline-variant-shimmer align-middle"></i>
                </span>
              </div>
              <div class="flex-grow-1 ms-3">
                <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">
                  KPI Percentage
                </p>
                <h4 class="mb-0">
                  <CountTo :startVal="0"
                           :endVal="manager.completion.target"
                           :duration="2000" :decimals="1"
                           suffix="%"
                  />
                </h4>
              </div>
              <div class="flex-shrink-0 align-self-end">
                <span class="badge bg-light text-body">
                  <i class="ri-arrow-up-s-fill align-middle me-1"></i>
                  <span v-b-tooltip.hover
                        :title="`Points earned: ${(a_inquires_count)} * 3 + ${b_inquires_count} * 2 + ${c_inquires_count} * (-1)`">
                    {{ (a_inquires_count * 3) + (b_inquires_count * 2) + (c_inquires_count * (-1)) }}
                  </span>
                  <i class="mdi mdi-slash-forward"></i>
                  <span v-b-tooltip.hover
                        :title="`Total max points: ${(a_inquires_count + b_inquires_count + c_inquires_count)} * 3`"
                  >
                    {{ (a_inquires_count + b_inquires_count + c_inquires_count) * 3 }}
                  </span>
                  <i class="mdi mdi-approximately-equal ms-1"></i>
                  {{ manager.completion.target }}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow-none border mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-success text-success rounded fs-3 material-shadow">
                  A
                </span>
              </div>
              <div class="flex-grow-1 ms-3">
                <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">
                  Inquires with grade A
                </p>
                <h4 class="mb-0">
                  <CountTo :startVal="0"
                           :endVal="a_inquires_count"
                           :duration="2000"
                  />
                </h4>
              </div>
              <div class="flex-shrink-0 align-self-end">
                <span
                    v-b-tooltip.hover :title="`${a_inquires_count} * 3 = ${a_inquires_count * 3}`"
                    class="badge border border-success text-success">
                  <i class="mdi mdi-plus align-middle me-1"></i> {{ a_inquires_count * 3 }} points
                </span>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow-none border mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-warning text-warning rounded fs-3 material-shadow">
                  B
                </span>
              </div>
              <div class="flex-grow-1 ms-3">
                <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">
                  Inquires with grade B
                </p>
                <h4 class="mb-0">
                  <CountTo :startVal="0"
                           :endVal="b_inquires_count"
                           :duration="2000"
                  />
                </h4>
              </div>
              <div class="flex-shrink-0 align-self-end">
                <span
                    v-b-tooltip.hover :title="`${b_inquires_count} * 2 = ${b_inquires_count * 2}`"
                    class="badge border border-warning text-warning">
                  <i class="mdi mdi-plus align-middle me-1"></i> {{ b_inquires_count * 2 }} points
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow-none border mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-danger text-danger fs-3 rounded material-shadow">
                  C
                </span>
              </div>
              <div class="flex-grow-1 ms-3">
                <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">
                  Inquires with grade C
                </p>
                <h4 class="mb-0">
                  <CountTo :startVal="0"
                           :endVal="c_inquires_count"
                           :duration="2000"
                  />
                </h4>
              </div>
              <div class="flex-shrink-0 align-self-end">
                <span v-b-tooltip.hover :title="`${c_inquires_count} * (-1) = ${c_inquires_count * (-1)}`"
                      class="badge border border-danger text-danger">
                  {{ c_inquires_count * (-1) }} points
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <kpi-item
        icon="ri-question-answer-line"
        title="Follow-up KPI"
        description="The performance percentage based on how quickly inquiries are marked as success or fail after being quoted."
        target="To complete 100% of inquiries with grade A"
        :weight="10"
        :grades="[
          { label: 'A', description: 'Completed within 5 days. Inquiries in this timeframe are worth 3 points.', badgeClass: 'success' },
          { label: 'B', description: 'Completed between 6 and 7 days. Inquiries in this timeframe are worth 2 points.', badgeClass: 'warning' },
          { label: 'C', description: 'Completed after 7 days. Inquiries in this timeframe are subject to a 1-point reduction.', badgeClass: 'danger' }
        ]"
    />

    <div class="table-responsive mt-3">
      <table class="table table-bordered table-nowrap">
        <thead class="table-light">
        <tr>
          <th class="text-center" scope="col">#</th>
          <th class="text-center" scope="col"></th>
          <th scope="col">Inquiry Message</th>
          <th scope="col">Description</th>
          <th scope="col">Customer</th>
          <th scope="col">Date Created</th>
          <th scope="col">
            <div class="d-flex align-items-center gap-2">
              <span class="text-warning">Pending</span>
              <i class="mdi mdi-arrow-right text-muted"></i>
              <span class="text-secondary">Quote</span>
            </div>
          </th>
          <th scope="col">
            <div class="d-flex align-items-center gap-2">
              <span class="text-secondary">Quote</span>
              <i class="mdi mdi-arrow-right text-muted"></i>
              <div>
                <span class="text-success">Success</span>
                <i class="mdi mdi-slash-forward text-muted"></i>
                <span class="text-danger">Fail</span>
              </div>
            </div>
          </th>
          <th class="text-center" scope="col">Response Time Grade</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(inquiry, index) in inquires" :key="`inquiry_${index}_${inquiry.id}`">
          <tr class="align-middle">
            <th class="text-center" scope="row">{{ index + 1 }}</th>
            <th class="text-center" scope="row">{{ inquiry.platform }}</th>
            <td style="max-width: 150px">
              <p class="mb-0 text-truncate pe-2"
                 v-b-tooltip.hover :title="cleanText(inquiry.text)"
                 v-html="cleanText(inquiry.text)"></p>
            </td>
            <td style="max-width: 150px">
              <p class="mb-0 text-truncate pe-2"
                 v-b-tooltip.hover :title="cleanText(inquiry.description)"
                 v-html="cleanText(inquiry.description)"></p>
            </td>
            <td style="max-width: 150px">
              <p v-b-tooltip.hover :title="cleanText(inquiry.client)"
                 class="mb-0 text-truncate pe-2">{{ inquiry.client }}</p>
            </td>
            <td>
              <div v-b-tooltip.hover :title="`Sender: ${inquiry.sender__username}. Sales manager: ${manager_username}`">
                {{ formatDate(inquiry.created) }}
                <small class="d-block text-muted">By {{ inquiry.sender__username || '-' }}</small>
              </div>
            </td>
            <td>
              <template v-if="inquiry.status === 'pending'">
                <div
                    v-b-tooltip.hover
                    :title="`${calculateDuration(inquiry.created, new Date())}`"
                >
                  Still <span class="text-warning fw-medium">Pending</span>
                  <small class="d-block text-muted">Since {{ formatDate(inquiry.created) }}</small>
                </div>
              </template>
              <div v-b-tooltip.hover :title="`Quoted at: ${formatDate(inquiry.quoted_at)}`" v-else>
                <div>
                  <span class="fw-medium text-secondary">Quoted</span>
                  <i class="mdi mdi-arrow-right text-muted mx-1"></i>
                  <span class="text-capitalize"> {{ durationInDays(inquiry.quote_time) }}</span>
                </div>
                <small class="text-muted">
                  <i class="mdi mdi-clock-outline me-1"></i>
                  {{ formatDuration(inquiry.quote_time) }}
                </small>
              </div>
            </td>
            <td>
              <template v-if="inquiry.auto_completion">
                <div
                    v-b-tooltip.hover
                    :title="`Auto ${inquiry.status === 'fail' ? 'Fail' : 'Success'}: ${inquiry.status === 'fail'
                    ? formatDate(inquiry.fail_at) : formatDate(inquiry.success_at)}`"
                    class="d-flex flex-column align-items-start justify-content-start gap-1"
                >
                  <div>
                    <span class="fw-medium text-capitalize" :class="{
                      'text-danger': inquiry.status === 'fail',
                      'text-success': inquiry.status === 'success',
                    }">
                       <i class="mdi mdi-robot-outline me-1"></i>
                      Auto {{ inquiry.status }}
                    </span>
                    <i class="mdi mdi-arrow-right text-muted ms-1"></i>
                    <span class="text-capitalize ms-1">{{ durationInDays(inquiry.resolution_time) }}</span>
                  </div>
                  <small class="text-muted">
                    <i class="mdi mdi-clock-outline me-1"></i>
                    {{ formatDuration(inquiry.resolution_time) }}
                  </small>
                </div>
              </template>
              <template v-else>
                <div
                    v-b-tooltip.hover
                    :title="`${inquiry.status === 'fail'
                    ? formatDate(inquiry.fail_at) : formatDate(inquiry.success_at)}`"
                    v-if="['success', 'fail'].includes(inquiry.status)"
                    class="d-flex flex-column align-items-start justify-content-start gap-1">
                  <div>
                    <span class="fw-medium text-capitalize" :class="{
                      'text-danger': inquiry.status === 'fail',
                      'text-success': inquiry.status === 'success',
                    }">
                      {{ inquiry.status }}
                    </span>
                    <i class="mdi mdi-arrow-right text-muted ms-1"></i>
                    <span class="text-capitalize ms-1"> {{ durationInDays(inquiry.resolution_time) }}</span>
                  </div>
                  <small class="text-muted">
                    <i class="mdi mdi-clock-outline me-1"></i>
                    {{ formatDuration(inquiry.resolution_time) }}
                  </small>
                </div>
                <div
                    v-b-tooltip.hover
                    :title="`${calculateDuration(inquiry.quoted_at, new Date())}`"
                    v-else-if="inquiry.status === 'quoted'">
                  Still <span class="text-secondary fw-medium">Quoted</span>
                  <small class="d-block text-muted">Since {{ formatDate(inquiry.quoted_at) }}</small>
                </div>
              </template>
            </td>
            <td class="text-center">
              <div class="d-flex justify-content-center">
                <div v-b-tooltip.hover
                     :title="`Because ${inquiry.auto_completion ? 'System' : 'Manager'} marked as ${inquiry.status} ${durationInDays(inquiry.resolution_time)}`"
                     class="avatar-sm y position-relative"
                >
                  <span v-if="inquiry.auto_completion"
                        class="position-absolute top-0 start-100 translate-middle badge
                         rounded-pill bg-danger"
                  >
                    <i class="mdi mdi-robot-outline fs-14"></i>
                    <span class="visually-hidden">unread messages</span>
                  </span>
                  <span class="avatar-title rounded fs-3" :class="{
                    'bg-soft-success text-success': inquiry.completion_grade === 'A',
                    'bg-soft-warning text-warning': inquiry.completion_grade === 'B',
                    'bg-soft-danger text-danger': inquiry.completion_grade === 'C',
                  }">
                      {{ inquiry.completion_grade }}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>

  </b-modal>
</template>
