<template>
  <b-list-group>
    <kpi-item
        icon="mdi mdi-clock-outline"
        title="Response Time KPI"
        description="The percentage of performance based on how quickly inquiries are quoted by a manager."
        target="To quote 100% of inquiries with grade A"
        :weight="20"
        :grades="[
            { label: 'A', description: 'Quoted within 48 hours. Inquiries in this timeframe are worth 3 points.', badgeClass: 'success' },
            { label: 'B', description: 'Quoted between 48 and 72 hours. Inquiries in this timeframe are worth 2 points.', badgeClass: 'warning' },
            { label: 'C', description: 'Quoted after 72 hours. Inquiries in this timeframe are subject to a 1-point reduction.', badgeClass: 'danger' }
        ]"
    />

    <kpi-item
        icon="ri-question-answer-line"
        title="Follow-up KPI"
        description="The performance percentage based on how quickly inquiries are marked as success or fail after being quoted."
        target="To complete 100% of inquiries with grade A"
        :weight="10"
        :grades="[
          { label: 'A', description: 'Completed within 5 days. Inquiries in this timeframe are worth 3 points.', badgeClass: 'success' },
          { label: 'B', description: 'Completed between 6 and 7 days. Inquiries in this timeframe are worth 2 points.', badgeClass: 'warning' },
          { label: 'C', description: 'Completed after 7 days. Inquiries in this timeframe are subject to a 1-point reduction.', badgeClass: 'danger' }
        ]"
    />

    <kpi-item
        icon="mdi mdi-check-circle-outline"
        title="Conversion Rate KPI"
        description="Shows the percentage of successful inquiries in relation to the total."
        target="To successfully complete at least 10% of total inquiries"
        :weight="30"
    />

    <kpi-item
        icon="mdi mdi-call-received"
        title="New Leads Generated"
        description="Shows the total number of new inquiries generated by a manager."
        target="To create 10 inquiries with different new clients"
        :weight="40"
    />
  </b-list-group>
</template>

<script>
import KpiItem from './KpiItem.vue'; // Assuming KpiItem is the component created for KPI items

export default {
  components: {
    KpiItem,
  },
};
</script>
