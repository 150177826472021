<script>
import moment from "moment";
import axios from "axios";
import {CountTo} from "vue3-count-to";
// import KpiItem from "@/components/kpis/KpiItem.vue";

export default {
  name: "ResponseTimeKPI",
  props: {
    date_from: {
      type: [String, null],
      required: false,
      default: () => null
    },
    date_to: {
      type: [String, null],
      required: false,
      default: () => null
    },
  },
  data() {
    return {
      showModal: false,
      manager: null,
      inquiry_status: 'all',
      active_tab: 'kpi',
      grade_to_marks: {
        "A": 3,
        "B": 2,
        "C": -1
      },
      kpi_bonus_percentage: 90,

      inquires: [],
      validStatuses: ['pending', 'quoted', 'success', 'fail'],
      is_loading: true
    }
  },
  components: {
    CountTo,
  },
  emits: ['provideManagerData'],
  inject: ['response_time_kpi_bonus_percentage'],
  computed: {
    average_response_time() {
      let response_times = this.inquires.filter(i => i.status !== 'pending').map(i => i.quote_time)

      return this.calculateAverageDuration(response_times)
    },
    average_completion_time() {
      let completion_times = this.inquires.filter(i => i.status === 'success' || i.status === 'fail').map(i => i.resolution_time)

      return this.calculateAverageDuration(completion_times)
    },
    a_inquires_count() {
      return this.inquires.filter(i => i.quote_grade === 'A').length
    },
    b_inquires_count() {
      return this.inquires.filter(i => i.quote_grade === 'B').length
    },
    c_inquires_count() {
      return this.inquires.filter(i => i.quote_grade === 'C').length
    },
    manager_username() {
      return this.manager.manager
          ? this.manager.manager.full_name.trim().length > 0 ? this.manager.manager.full_name : this.manager.manager.username
          : 'Unknown Manager'
    }
  },
  methods: {
    calculateAverageDuration(durations) {
      const totalSeconds = durations
          .filter(duration => duration !== "00:00:00")
          .reduce((sum, duration) => sum + this.durationToSeconds(duration), 0);

      const nonZeroCount = durations.filter(duration => duration !== "00:00:00").length;

      const averageSeconds = totalSeconds / nonZeroCount;

      return this.secondsToDuration(averageSeconds);
    },
    durationToSeconds(duration) {
      if (duration === "00:00:00") return 0;

      const parts = duration.split(" ");
      let days = 0;
      let timeStr = duration;

      if (parts.length > 1) {
        days = parseInt(parts[0]);
        timeStr = parts[1];
      }

      const [hours, minutes, seconds] = timeStr.split(":").map(Number);

      return (days * 24 * 3600) + (hours * 3600) + (minutes * 60) + seconds;
    },
    secondsToDuration(totalSeconds) {
      const days = Math.floor(totalSeconds / (24 * 3600));
      totalSeconds %= (24 * 3600);
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = Math.floor(totalSeconds % 60);
      const milliseconds = Math.round((totalSeconds % 1) * 1000000);

      let result = '';
      if (days > 0) {
        result += `${days} `;
      }

      return result + `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds).padStart(6, '0')}`;
    },
    async openModal(manager, active_tab, inquiry_status) {
      this.manager = manager
      this.active_tab = active_tab

      if (inquiry_status) {
        this.inquiry_status = inquiry_status
      } else {
        this.inquiry_status = 'all'
      }

      await this.$router.push({
        query: {
          start_date: this.$route.query.start_date || undefined,
          end_date: this.$route.query.end_date || undefined,
          sales_manager_id: this.manager.manager.id,
          filter_by_status: this.inquiry_status
        }
      })

      this.showModal = true

      await this.getInquires()
    },
    async getInquires() {
      this.inquires = []
      try {
        this.is_loading = true
        let url = this.active_tab === 'kpi' ? '/inquiry/statistic/kpi/detail/' : '/inquiry/statistic/detail/'
        let response = await axios.get(url, {
          params: {
            manager_id: this.manager.manager.id,
            status: this.inquiry_status === 'all' ? undefined : (this.validStatuses.includes(this.inquiry_status) ? this.inquiry_status : undefined),
            date_from: this.date_from ? this.date_from : undefined,
            date_to: this.date_to ? this.date_to : undefined,
          }
        })
        this.inquires = response.data.results || []
        this.is_loading = false
      } catch {
        this.is_loading = false
        alert("error")
      }
    },
    async closeModal() {
      if (this.$route.query.sales_manager_id) {
        let query = {...this.$route.query}
        delete query.sales_manager_id
        delete query.filter_by_status
        await this.$router.push({query: query})
      } else {
        this.showModal = false
      }
    },
    cleanText(html) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText;
    },
    formatDate(date) {
      return moment(date).format('lll')
    },
    calculateDuration(startDate, endDate) {
      const now = moment(endDate); // Get the current time
      const start = moment(startDate); // Parse the start date
      const durationInSeconds = now.diff(start, 'seconds'); // Calculate the difference in seconds

      const duration = moment.duration(durationInSeconds, 'seconds');

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let formattedDuration = '';

      if (days > 0) {
        formattedDuration += `${days} days `;
      }

      formattedDuration += `${hours} hours ${minutes} minutes ${seconds} seconds`;

      return formattedDuration.trim();
    },
    formatDuration(durationInSeconds) {

      if (this.is_loading) return '0'

      const duration = moment.duration(durationInSeconds, 'seconds');

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let formattedDuration = '';

      if (days > 0) {
        formattedDuration += `${days} ${days > 1 ? 'days' : 'day'} `;
      }

      formattedDuration += `${hours} hours ${minutes} minutes`;

      if (days <= 0) {
        formattedDuration += `${seconds} seconds`;
      }

      return formattedDuration.trim(); // Trim any extra spaces
    },
    durationInDays(durationInSeconds) {
      const duration = moment.duration(durationInSeconds, 'seconds');

      const totalHours = duration.asHours();
      const days = Math.floor(duration.asDays());

      let daysDescription;

      if (totalHours < 48) {
        daysDescription = "within 2 days";
      } else {
        daysDescription = `after ${days} days`;
      }

      return daysDescription
    }
  },
  watch: {
    "$route.query.sales_manager_id": {
      handler(newValue) {
        if (this.$route.query.filter_by_status) {
          setTimeout(() => {
            this.showModal = !!newValue;
          }, 500)
        } else {
          this.showModal = false
        }
      },
      immediate: true,
      deep: true
    },
    showModal(newValue) {
      if (!newValue) {
        setTimeout(async () => {
          let query = {...this.$route.query}
          delete query.sales_manager_id
          delete query.filter_by_status
          await this.$router.push({
            query: query
          })
        }, 500)
      }
    }
  },
}
</script>

<template>
  <b-modal v-if="manager" v-model="showModal"
           hide-footer hide-header centered
           fullscreen
  >
    <div class="d-flex align-items-center mb-4 gap-3">
      <div @click="closeModal()" class="flex-shrink-0 avatar-sm cursor-pointer">
        <div class="avatar-title bg-soft-primary text-primary fs-22 rounded">
          <i class="mdi mdi-arrow-left"></i>
        </div>
      </div>
      <div>
        <h4 class="mb-1 text-uppercase">
          {{ inquiry_status }} INQUIRES
        </h4>
        <span class="text-muted">
          By {{ manager_username }}
        </span>
      </div>
    </div>

    <div class="row mb-3" v-if="inquiry_status === 'all' && active_tab === 'kpi'">
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow-none border mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="avatar-sm flex-shrink-0">
                    <span class="avatar-title bg-light text-primary rounded fs-3 material-shadow">
                      <i class="mdi mdi-chart-timeline-variant-shimmer align-middle"></i>
                    </span>
              </div>
              <div class="flex-grow-1 ms-3">
                <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">
                  Overall KPI Percentage
                </p>
                <h4 class="mb-0">
                  <CountTo :startVal="0"
                           :endVal="manager.overall_percentage"
                           :duration="2000" :decimals="1"
                           suffix="%"
                  />
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow-none border mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="avatar-sm flex-shrink-0">
                    <span class="avatar-title bg-soft-secondary text-secondary rounded fs-3 material-shadow">
                      <i class="mdi mdi-clock-outline"></i>
                    </span>
              </div>
              <div class="flex-grow-1 ms-3">
                <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">
                  Average Response time
                </p>
                <h4 class="mb-0">
                  {{ formatDuration(average_response_time) }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow-none border mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="avatar-sm flex-shrink-0">
                <span
                    class="position-relative avatar-title overflow-hidden bg-transparent text-success rounded fs-3 material-shadow">
                  <i class="mdi mdi-clock-outline"></i>
                  <span class="position-absolute bg-soft-danger"
                        style="z-index: 1550; width: 100px; top: 10px; left: -4px; transform: rotate(-45deg)">
                    .
                  </span>
                      <span class="position-absolute bg-soft-success"
                            style="z-index: 1550; width: 100px; top: 6.5px; right: -4px; transform: rotate(-45deg)">
      .
    </span>
                </span>
              </div>
              <div class="flex-grow-1 ms-3">
                <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">
                  Average Completion time
                </p>
                <h4 class="mb-0">
                  {{ formatDuration(average_completion_time) }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow-none border mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="avatar-sm flex-shrink-0">
                    <span class="avatar-title bg-soft-success text-success rounded fs-3 material-shadow">
                      <i class="mdi mdi-check-outline"></i>
                    </span>
              </div>
              <div class="flex-grow-1 ms-3">
                <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">
                  Conversion rate
                </p>
                <h4 class="mb-0">
                  {{ manager.conversion.actual }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center gap-2">
      <span>Date range: </span>
      <div v-if="date_from && date_to" class="d-flex align-items-center gap-1">
        <span>From <b>{{ date_from }}</b></span>
        <span>to <b>{{ date_to }}</b></span>
      </div>
      <b v-else>All Time</b>
    </div>

    <div class="table-responsive mt-3">
      <table class="table table-bordered table-nowrap">
        <thead class="table-light">
        <tr>
          <th class="text-center" scope="col">#</th>
          <th class="text-center" scope="col">#</th>
          <th scope="col">Inquiry Message</th>
          <th scope="col">Description</th>
          <th scope="col">Customer</th>
          <th scope="col">Date Created</th>
          <th v-if="active_tab === 'all'" scope="col">Status</th>
          <template v-if="active_tab === 'kpi'">
            <th scope="col">
              <div class="d-flex align-items-center gap-2">
                <span class="text-warning">Pending</span>
                <i class="mdi mdi-arrow-right text-muted"></i>
                <span class="text-secondary">Quote</span>
              </div>
            </th>
            <th scope="col">
              <div class="d-flex align-items-center gap-2">
                <span class="text-secondary">Quote</span>
                <i class="mdi mdi-arrow-right text-muted"></i>
                <div>
                  <span class="text-success">Success</span>
                  <i class="mdi mdi-slash-forward text-muted"></i>
                  <span class="text-danger">Fail</span>
                </div>
              </div>
            </th>
            <th class="text-center" scope="col">Response Time Grade</th>
            <th class="text-center" scope="col">Follow-up KPI</th>
            <th class="text-center" scope="col">Leads KPI</th>
          </template>
        </tr>
        </thead>
        <tbody>
        <tr v-if="is_loading">
          <td :colspan="active_tab === 'kpi' ? 6: 10">
            <div class="d-flex align-items-center gap-4">
              <b-spinner variant="primary"></b-spinner>
              <span>Loading...</span>
            </div>
          </td>
        </tr>
        <template v-for="(inquiry, index) in inquires" :key="`inquiry_${index}_${inquiry.id}`">
          <tr class="align-middle">
            <th class="text-center" scope="row">{{ index + 1 }}</th>
            <th class="text-center" scope="row">{{ inquiry.platform }}</th>
            <td style="max-width: 150px">
              <p class="mb-0 text-truncate pe-2"
                 v-b-tooltip.hover :title="cleanText(inquiry.text)"
                 v-html="cleanText(inquiry.text)"></p>
            </td>
            <td style="max-width: 150px">
              <p class="mb-0 text-truncate pe-2"
                 v-b-tooltip.hover :title="cleanText(inquiry.description)"
                 v-html="cleanText(inquiry.description)"></p>
            </td>
            <td style="max-width: 150px">
              <p v-b-tooltip.hover :title="cleanText(inquiry.client)"
                 class="mb-0 text-truncate pe-2">{{ inquiry.client }}</p>
            </td>
            <td>
              <div v-b-tooltip.hover :title="`Sender: ${inquiry.sender__username}. Sales manager: ${manager_username}`">
                {{ formatDate(inquiry.created) }}
                <small class="d-block text-muted">By {{ inquiry.sender__username || '-' }}</small>
              </div>
            </td>
            <td v-if="active_tab === 'all'">
              <div
                  v-b-tooltip.hover
                  :title="`${formatDuration(inquiry.created, new Date())}`"
                  v-if="inquiry.status === 'pending'">
                <span class="text-capitalize fw-medium text-warning d-block">Pending</span>
                <span class="text-muted">
                  Since {{ formatDate(inquiry.created) }}
                </span>
              </div>

              <div v-b-tooltip.hover
                   :title="`Response time: ${formatDuration(inquiry.quote_time)}`"
                   v-else-if="inquiry.status === 'quoted'"
              >
                <span class="text-capitalize fw-medium text-secondary d-block">Quoted</span>
                <span class="text-muted">
                  At {{ formatDate(inquiry.quoted_at) }}
                </span>
              </div>

              <div v-b-tooltip.hover
                   :title="`Completion time: ${formatDuration(inquiry.resolution_time)}`"
                   v-else-if="inquiry.status === 'success'"
              >
                <span class="text-capitalize fw-medium text-success d-block">Success</span>
                <span class="text-muted">
                  At {{ formatDate(inquiry.success_at) }}
                </span>
              </div>

              <div v-b-tooltip.hover
                   :title="`Completion time: ${formatDuration(inquiry.resolution_time)}`"
                   v-else-if="inquiry.status === 'fail'"
              >
                <span class="text-capitalize fw-medium text-danger d-block">Fail</span>
                <span class="text-muted">
                  At {{ formatDate(inquiry.fail_at) }}
                </span>
              </div>


            </td>
            <template v-if="active_tab === 'kpi'">
              <td>
                <template v-if="inquiry.status === 'pending'">
                  <div
                      v-b-tooltip.hover
                      :title="`${calculateDuration(inquiry.created, new Date())}`"
                  >
                    Still <span class="text-warning fw-medium">Pending</span>
                    <small class="d-block text-muted">Since {{ formatDate(inquiry.created) }}</small>
                  </div>
                </template>
                <div v-b-tooltip.hover :title="`Quoted at: ${formatDate(inquiry.quoted_at)}`" v-else>
                  <div>
                    <span class="fw-medium text-secondary">Quoted</span>
                    <i class="mdi mdi-arrow-right text-muted mx-1"></i>
                    <span class="text-capitalize"> {{ durationInDays(inquiry.quote_time) }}</span>
                  </div>
                  <small class="text-muted">
                    <i class="mdi mdi-clock-outline me-1"></i>
                    {{ formatDuration(inquiry.quote_time) }}
                  </small>
                </div>
              </td>
              <td>
                <template v-if="inquiry.auto_completion">
                  <div
                      v-b-tooltip.hover
                      :title="`Auto ${inquiry.status === 'fail' ? 'Fail' : 'Success'}: ${inquiry.status === 'fail'
                    ? formatDate(inquiry.fail_at) : formatDate(inquiry.success_at)}`"
                      class="d-flex flex-column align-items-start justify-content-start gap-1"
                  >
                    <div>
                    <span class="fw-medium text-capitalize" :class="{
                      'text-danger': inquiry.status === 'fail',
                      'text-success': inquiry.status === 'success',
                    }">
                       <i class="mdi mdi-robot-outline me-1"></i>
                      Auto {{ inquiry.status }}
                    </span>
                      <i class="mdi mdi-arrow-right text-muted ms-1"></i>
                      <span class="text-capitalize">{{ durationInDays(inquiry.resolution_time) }}</span>
                    </div>
                    <small class="text-muted">
                      <i class="mdi mdi-clock-outline me-1"></i>
                      {{ formatDuration(inquiry.resolution_time) }}
                    </small>
                  </div>
                </template>
                <template v-else>
                  <div
                      v-b-tooltip.hover
                      :title="`${inquiry.status === 'fail'
                    ? formatDate(inquiry.fail_at) : formatDate(inquiry.success_at)}`"
                      v-if="['success', 'fail'].includes(inquiry.status)"
                      class="d-flex flex-column align-items-start justify-content-start gap-1">
                    <div>
                    <span class="fw-medium text-capitalize" :class="{
                      'text-danger': inquiry.status === 'fail',
                      'text-success': inquiry.status === 'success',
                    }">
                      {{ inquiry.status }}
                    </span>
                      <i class="mdi mdi-arrow-right text-muted mx-1"></i>
                      <span class="text-capitalize"> {{ durationInDays(inquiry.resolution_time) }}</span>
                    </div>
                    <small class="text-muted">
                      <i class="mdi mdi-clock-outline me-1"></i>
                      {{ formatDuration(inquiry.resolution_time) }}
                    </small>
                  </div>
                  <div
                      v-b-tooltip.hover
                      :title="`${calculateDuration(inquiry.quoted_at, new Date())}`"
                      v-else-if="inquiry.status === 'quoted'">
                    Still <span class="text-secondary fw-medium">Quoted</span>
                    <small class="d-block text-muted">Since {{ formatDate(inquiry.quoted_at) }}</small>
                  </div>
                </template>
              </td>
              <td class="text-center">
                <div v-if="inquiry.status !== 'pending'" class="d-flex justify-content-center">
                  <div v-b-tooltip.hover
                       :title="`Because manager quoted ${durationInDays(inquiry.quote_time)}`"
                       class="avatar-sm"
                  >
                  <span class="avatar-title rounded fs-3" :class="{
                'bg-soft-success text-success': inquiry.quote_grade === 'A',
                'bg-soft-warning text-warning': inquiry.quote_grade === 'B',
                'bg-soft-danger text-danger': inquiry.quote_grade === 'C',
              }">
                  {{ inquiry.quote_grade }}
              </span>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div v-if="['success', 'fail'].includes(inquiry.status)" class="d-flex justify-content-center">
                  <div v-b-tooltip.hover
                       :title="`Because ${inquiry.auto_completion ? 'System' : 'Manager'} marked as ${inquiry.status} ${durationInDays(inquiry.resolution_time)}`"
                       class="avatar-sm y position-relative"
                  >
                  <span v-if="inquiry.auto_completion"
                        class="position-absolute top-0 start-100 translate-middle badge
                         rounded-pill bg-danger"
                  >
                    <i class="mdi mdi-robot-outline fs-14"></i>
                    <span class="visually-hidden">unread messages</span>
                  </span>
                    <span class="avatar-title rounded fs-3" :class="{
                    'bg-soft-success text-success': inquiry.completion_grade === 'A',
                    'bg-soft-warning text-warning': inquiry.completion_grade === 'B',
                    'bg-soft-danger text-danger': inquiry.completion_grade === 'C',
                  }">
                      {{ inquiry.completion_grade }}
                  </span>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div v-if="inquiry.is_new_customer" class="d-flex flex-column">
                  <i class="mdi mdi-account-check text-success fs-2"></i>
                  <small class="text-muted">New customer</small>
                </div>
              </td>
            </template>
          </tr>
        </template>
        </tbody>
      </table>
    </div>

  </b-modal>
</template>
