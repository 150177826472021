<script setup>
import {ref, onMounted} from "vue";
import {useRouter, useRoute} from "vue-router";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment/moment";

const router = useRouter();
const route = useRoute();

let selected_date_option = ref('all_time');
let show_custom_date_select_modal = ref(false);
let custom_date_config = ref({
  enableTime: false,
  altInput: true,
  dateFormat: "Z",
  altFormat: "d M, Y",
  mode: "range",
  date: '',
  inline: true
});
let date_options = ref([
  {label: 'All time', value: 'all_time'},
  {label: 'Last 7 days', value: 'last_7_days'},
  {label: 'Last 14 days', value: 'last_14_days'},
  {label: 'Last 30 days', value: 'last_30_days'},
  {
    label: 'Custom range...',
    value: 'custom_range',
    onClick: () => show_custom_date_select_modal.value = true
  },
]);

let month_options = ref([
  {value: 1, label: "January"},
  {value: 2, label: "February"},
  {value: 3, label: "March"},
  {value: 4, label: "April"},
  {value: 5, label: "May"},
  {value: 6, label: "June"},
  {value: 7, label: "July"},
  {value: 8, label: "August"},
  {value: 9, label: "September"},
  {value: 10, label: "October"},
  {value: 11, label: "November"},
  {value: 12, label: "December"}
])


const handleDateOptionChange = async (option) => {
  if (option.value === 'custom_range') {
    show_custom_date_select_modal.value = true;
  } else {
    const {start_date, end_date} = calculateDateRange(option);
    await updateRouterQuery(start_date, end_date);
  }
};

const calculateDateRange = (option) => {
  let end_date = moment().format('YYYY-MM-DD');
  let start_date;

  if (typeof option.value === 'string') {
    switch (option.value) {
      case 'last_7_days':
        start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
        break;
      case 'last_14_days':
        start_date = moment().subtract(14, 'days').format('YYYY-MM-DD');
        break;
      case 'last_30_days':
        start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
        break;
      case 'all_time':
        start_date = null
        break
      case 'custom_range':
        if (custom_date_config.value.date.length > 0) {
          start_date = moment(custom_date_config.value.date.split(' to ')[0]).format('YYYY-MM-DD');
          end_date = moment(custom_date_config.value.date.split(' to ')[1]).format('YYYY-MM-DD');
        } else {
          start_date = moment(route.query.start_date).format('YYYY-MM-DD');
          end_date = moment(route.query.end_date).format('YYYY-MM-DD');
        }
        break;
      default:
        start_date = null
        break;
    }
  } else {
    try {
      let month = month_options.value.find(i => i.value === option.value)
      start_date = moment().month(month.label).startOf('month').format('YYYY-MM-DD');
      end_date = moment().month(month.label).endOf('month').format('YYYY-MM-DD');
    } catch {
      start_date = null
      end_date = null
    }
  }

  return {start_date, end_date};
};

const updateRouterQuery = async (start_date, end_date) => {
  const query = {...route.query};

  if (start_date) {
    query.start_date = start_date;
    query.end_date = end_date;
  } else {
    delete query.start_date;
    delete query.end_date;
  }

  await router.push({query});
};

const filterByCustomDateRange = async () => {
  if (custom_date_config.value.date) {
    if (custom_date_config.value.date.split(' to ').length === 2) {
      show_custom_date_select_modal.value = false;
      let start_date = moment(custom_date_config.value.date.split(' to ')[0]).format('YYYY-MM-DD');
      let end_date = moment(custom_date_config.value.date.split(' to ')[1]).format('YYYY-MM-DD');
      date_options.value.find(i => i.value === 'custom_range').label = formatDate(start_date) + ' - ' + formatDate(end_date);

      await updateRouterQuery(start_date, end_date);
    }
  }
};

const formatDate = (date) => {
  if (!date) return '';
  return moment(date).format('MMM DD, YYYY');
};

const getDaysDifference = () => {
  if (!custom_date_config.value.date) return 0;
  const [startDate, endDate] = custom_date_config.value.date.split(' to ');
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days') + 1; // Add 1 to include both start and end dates
};

const isFullMonth = (start_date, end_date, month) => {
  const startOfMonth = moment().month(month).startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().month(month).endOf('month').format('YYYY-MM-DD');
  return start_date === startOfMonth && end_date === endOfMonth;
};

// Initialize the date range based on the URL parameters
onMounted(() => {
  const {start_date, end_date} = route.query;

  if (start_date && end_date) {
    const diff = moment(end_date).diff(moment(start_date), 'days');
    const today = moment(new Date()).format('YYYY-MM-DD');

    // Check if the range corresponds to a specific month
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    let foundMonth = false;

    months.forEach((month) => {
      if (isFullMonth(start_date, end_date, month)) {
        let option = month_options.value.find(i => i.label === month)
        selected_date_option.value = `month_${option.value}`;
        foundMonth = true;
      }
    });

    // If not a full month, fallback to predefined ranges or custom range
    if (!foundMonth) {
      if (today === end_date) {
        if (diff === 7) {
          selected_date_option.value = 'last_7_days';
        } else if (diff === 14) {
          selected_date_option.value = 'last_14_days';
        } else if (diff === 30 || diff === 31) {
          selected_date_option.value = 'last_30_days';
        } else {
          selected_date_option.value = 'custom_range';
          date_options.value.find(i => i.value === 'custom_range').label = `${formatDate(start_date)} - ${formatDate(end_date)}`;
        }
      } else {
        selected_date_option.value = 'custom_range';
        date_options.value.find(i => i.value === 'custom_range').label = `${formatDate(start_date)} - ${formatDate(end_date)}`;
      }
    }
  }
});

</script>

<template>

  <b-modal v-model="show_custom_date_select_modal" title="Select Custom Range" hide-footer centered>
    <flat-pickr placeholder="Select date"
                :config="custom_date_config"
                v-model="custom_date_config.date"
                class="form-control flatpickr-input w-100"
    >
    </flat-pickr>
    <div class="mt-3" v-if="custom_date_config.date">
      {{ formatDate(custom_date_config.date.split(' to ')[0]) }} -
      {{ formatDate(custom_date_config.date.split(' to ')[1]) }}
      <span v-if="custom_date_config.date.split(' to ').length === 2" class="text-muted">
        ({{ getDaysDifference() }} days)
      </span>
    </div>
    <div class="mt-3 text-center">
      <b-button v-if="custom_date_config.date"
                @click="filterByCustomDateRange()"
                :disabled="custom_date_config.date.split(' to ').length !== 2" variant="primary">Proceed
      </b-button>
    </div>
  </b-modal>


  <div class="row justify-content-between align-items-center mb-3">
    <div class="col-12">
      <b-button-group v-model="selected_date_option" class="w-100" role="group"
                      aria-label="Basic radio toggle button group">
        <template v-for="option in date_options" :key="`date_option_${option.value}`">
          <input type="radio" class="btn-check" name="btnradio" :id="`date_option_${option.value}`"
                 autocomplete="off" :value="`${option.value}`"
                 @click="handleDateOptionChange(option)"
                 v-model="selected_date_option"
          >
          <label class="btn btn-outline-primary" :for="`date_option_${option.value}`">
            {{ option.label }}
          </label>
        </template>
      </b-button-group>
    </div>
    <div class="col-12">
      <b-button-group v-model="selected_date_option" class="w-100 flex-wrap" role="group"
                      aria-label="Basic radio toggle button group"
      >
        <template v-for="option in month_options" :key="`date_option_${option.value}`">
          <input type="radio" class="btn-check" name="btnradio" :id="`date_option_${option.value}`"
                 autocomplete="off" :value="`month_${option.value}`"
                 @click="handleDateOptionChange(option)"
                 v-model="selected_date_option"
          >
          <label class="btn btn-outline-primary" :for="`date_option_${option.value}`">
            {{ option.label }}
          </label>
        </template>
      </b-button-group>
    </div>
  </div>
</template>

<style lang="scss">
[data-layout-mode="dark"] {
  .bg-white {
    background-color: #2a2f34 !important;
  }
}
</style>