<script setup>
import PageHeader from "@/components/page-header.vue";
import {
  InquiriesByManagers as InquiriesByManagers,
  OrdersByManagers as OrdersByManagers,
  Filters as Filters,
  PositionsWidgets as PositionsWidgets,
} from "@/views/pages/statistics/components"

let title = 'Statistics'
let items = [
  {
    text: "Home",
    href: "/",
  },
  {
    text: "Statistics",
    active: true,
  },
]
</script>

<template>
  <PageHeader :title="title" :items="items"/>

  <section>
    <div class="row justify-content-center">

      <div class="col-12 ">
        <Filters/>
        <PositionsWidgets/>
        <InquiriesByManagers/>
        <OrdersByManagers/>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>