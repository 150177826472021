<script>
import moment from "moment";
// import {CountTo} from "vue3-count-to";
import KpiItem from "@/components/kpis/KpiItem.vue";

export default {
  name: "ResponseTimeKPI",
  data() {
    return {
      showModal: false,
      manager: null,
      inquiry_grade: 'all',
      grade_to_marks: {
        "A": 3,
        "B": 2,
        "C": -1
      },
      kpi_bonus_percentage: 90
    }
  },
  components: {
    // CountTo,
    KpiItem
  },
  emits: ['provideManagerData'],
  inject: ['response_time_kpi_bonus_percentage'],
  computed: {
    inquires() {
      return this.manager.leads.details
    },
    manager_username() {
      return this.manager.manager
          ? this.manager.manager.full_name.trim().length > 0 ? this.manager.manager.full_name : this.manager.manager.username
          : 'Unknown Manager'
    }
  },
  methods: {
    async openModal(manager) {
      this.manager = manager

      await this.$router.push({
        query: {
          start_date: this.$route.query.start_date || undefined,
          end_date: this.$route.query.end_date || undefined,
          sales_manager_id: this.manager.manager.id,
          kpi: 'new_leads',
        }
      })

      this.showModal = true
    },
    async closeModal() {
      if (this.$route.query.sales_manager_id) {
        let query = {...this.$route.query}
        delete query.sales_manager_id
        delete query.kpi
        await this.$router.push({query: query})
      } else {
        this.showModal = false
      }
    },
    cleanText(html) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText;
    },
    formatDate(date) {
      return moment(date).format('lll')
    },
    calculateDuration(startDate, endDate) {
      const now = moment(endDate); // Get the current time
      const start = moment(startDate); // Parse the start date
      const durationInSeconds = now.diff(start, 'seconds'); // Calculate the difference in seconds

      const duration = moment.duration(durationInSeconds, 'seconds');

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let formattedDuration = '';

      if (days > 0) {
        formattedDuration += `${days} days `;
      }

      formattedDuration += `${hours} hours ${minutes} minutes ${seconds} seconds`;

      return formattedDuration.trim();
    },
    formatDuration(durationInSeconds) {
      const duration = moment.duration(durationInSeconds, 'seconds');

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let formattedDuration = '';

      if (days > 0) {
        formattedDuration += `${days} days `;
      }

      formattedDuration += `${hours} hours ${minutes} minutes ${seconds} seconds`;

      return formattedDuration.trim(); // Trim any extra spaces
    },
    durationInDays(durationInSeconds) {
      const duration = moment.duration(durationInSeconds, 'seconds');

      const totalHours = duration.asHours();
      const days = Math.floor(duration.asDays());

      let daysDescription;

      if (totalHours < 48) {
        daysDescription = "within 2 days";
      } else {
        daysDescription = `after ${days} days`;
      }

      return daysDescription
    }
  },
  watch: {
    "$route.query.sales_manager_id": {
      handler(newValue) {
        if (this.$route.query.kpi === 'new_leads') {
          setTimeout(() => {
            this.showModal = !!newValue;
          }, 500)
        } else {
          this.showModal = false
        }
      },
      immediate: true,
      deep: true
    },
    showModal(newValue) {
      if (!newValue) {
        setTimeout(async () => {
          let query = {...this.$route.query}
          delete query.sales_manager_id
          delete query.kpi
          await this.$router.push({query: query})
        }, 500)
      }
    }
  },
}
</script>

<template>
  <b-modal v-if="manager" v-model="showModal"
           hide-footer hide-header centered
           fullscreen
  >
    <div class="d-flex align-items-center mb-4 gap-3">
      <div @click="closeModal()" class="flex-shrink-0 avatar-sm cursor-pointer">
        <div class="avatar-title bg-soft-primary text-primary fs-22 rounded">
          <i class="mdi mdi-arrow-left"></i>
        </div>
      </div>
      <div>
        <h4 class="mb-1 text-body fw-medium">
          NEW LEADS KPI
        </h4>
        <span class="text-muted">
          By {{ manager_username }}
        </span>
      </div>
    </div>

    <b-list-group>
      <kpi-item
          icon="mdi mdi-call-received"
          title="New Leads Generated"
          :description="`Shows the total number of new inquiries generated by ${manager_username}`"
          target="To create 10 inquiries with different new clients"
          :weight="40"
      />
    </b-list-group>

    <div class="table-responsive mt-3">
      <table class="table table-bordered table-nowrap">
        <thead class="table-light">
        <tr>
          <th class="text-center" scope="col">#</th>
          <th class="text-center" scope="col"></th>
          <th scope="col">Inquiry Message</th>
          <th scope="col">Description</th>
          <th scope="col">Customer</th>
          <th scope="col">Date Created</th>
          <th scope="col">
            <div class="d-flex align-items-center gap-2">
              <span class="text-warning">Pending</span>
              <i class="mdi mdi-arrow-right text-muted"></i>
              <span class="text-secondary">Quote</span>
            </div>
          </th>
          <th scope="col">
            <div class="d-flex align-items-center gap-2">
              <span class="text-secondary">Quote</span>
              <i class="mdi mdi-arrow-right text-muted"></i>
              <div>
                <span class="text-success">Success</span>
                <i class="mdi mdi-slash-forward text-muted"></i>
                <span class="text-danger">Fail</span>
              </div>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(inquiry, index) in inquires" :key="`inquiry_${index}_${inquiry.id}`">
          <tr class="align-middle">
            <th class="text-center" scope="row">{{ index + 1 }}</th>
            <th class="text-center" scope="row">{{ inquiry.platform }}</th>
            <td style="max-width: 200px">
              <p class="mb-0 text-truncate pe-2"
                 v-b-tooltip.hover :title="cleanText(inquiry.text)"
                 v-html="cleanText(inquiry.text)"></p>
            </td>
            <td style="max-width: 200px">
              <p class="mb-0 text-truncate pe-2"
                 v-b-tooltip.hover :title="cleanText(inquiry.description)"
                 v-html="cleanText(inquiry.description)"></p>
            </td>
            <td style="max-width: 200px">
              <p v-b-tooltip.hover :title="cleanText(inquiry.client)"
                 class="mb-0 text-truncate pe-2">{{ inquiry.client }}</p>
            </td>
            <td>
              <div v-b-tooltip.hover :title="`Sender: ${inquiry.sender__username}. Sales manager: ${manager_username}`">
                {{ formatDate(inquiry.created) }}
                <small class="d-block text-muted">By {{ inquiry.sender__username || '-' }}</small>
              </div>
            </td>
            <td>
              <template v-if="inquiry.status === 'pending'">
                <div
                    v-b-tooltip.hover
                    :title="`${calculateDuration(inquiry.created, new Date())}`"
                >
                  Still <span class="text-warning fw-medium">Pending</span>
                  <small class="d-block text-muted">Since {{ formatDate(inquiry.created) }}</small>
                </div>
              </template>
              <div v-b-tooltip.hover :title="`Quoted at: ${formatDate(inquiry.quoted_at)}`" v-else>
                <div>
                  <span class="fw-medium text-secondary">Quoted</span>
                  <i class="mdi mdi-arrow-right text-muted mx-1"></i>
                  <span class="text-capitalize"> {{ durationInDays(inquiry.quote_time) }}</span>
                </div>
                <small class="text-muted">
                  <i class="mdi mdi-clock-outline me-1"></i>
                  {{ formatDuration(inquiry.quote_time) }}
                </small>
              </div>
            </td>
            <td>
              <template v-if="inquiry.auto_completion">
                <div
                    v-b-tooltip.hover
                    :title="`Auto ${inquiry.status === 'fail' ? 'Fail' : 'Success'}: ${inquiry.status === 'fail'
                    ? formatDate(inquiry.fail_at) : formatDate(inquiry.success_at)}`"
                    class="d-flex flex-column align-items-start justify-content-start gap-1"
                >
                  <div>
                    <span class="fw-medium text-capitalize" :class="{
                      'text-danger': inquiry.status === 'fail',
                      'text-success': inquiry.status === 'success',
                    }">
                       <i class="mdi mdi-robot-outline me-1"></i>
                      Auto {{ inquiry.status }}
                    </span>
                    <i class="mdi mdi-arrow-right text-muted ms-1"></i>
                    <span class="text-capitalize ms-1">{{ durationInDays(inquiry.resolution_time) }}</span>
                  </div>
                  <small class="text-muted">
                    <i class="mdi mdi-clock-outline me-1"></i>
                    {{ formatDuration(inquiry.resolution_time) }}
                  </small>
                </div>
              </template>
              <template v-else>
                <div
                    v-b-tooltip.hover
                    :title="`${inquiry.status === 'fail'
                    ? formatDate(inquiry.fail_at) : formatDate(inquiry.success_at)}`"
                    v-if="['success', 'fail'].includes(inquiry.status)"
                    class="d-flex flex-column align-items-start justify-content-start gap-1">
                  <div>
                    <span class="fw-medium text-capitalize" :class="{
                      'text-danger': inquiry.status === 'fail',
                      'text-success': inquiry.status === 'success',
                    }">
                      {{ inquiry.status }}
                    </span>
                    <i class="mdi mdi-arrow-right text-muted ms-1"></i>
                    <span class="text-capitalize ms-1"> {{ durationInDays(inquiry.resolution_time) }}</span>
                  </div>
                  <small class="text-muted">
                    <i class="mdi mdi-clock-outline me-1"></i>
                    {{ formatDuration(inquiry.resolution_time) }}
                  </small>
                </div>
                <div
                    v-b-tooltip.hover
                    :title="`${calculateDuration(inquiry.quoted_at, new Date())}`"
                    v-else-if="inquiry.status === 'quoted'">
                  Still <span class="text-secondary fw-medium">Quoted</span>
                  <small class="d-block text-muted">Since {{ formatDate(inquiry.quoted_at) }}</small>
                </div>
              </template>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>

  </b-modal>
</template>
