<template>
  <b-list-group-item class="d-flex justify-content-between align-items-end gap-4">
    <div>
      <h6 class="text-body mb-1">
        <i :class="icon + ' align-middle me-2'"></i>
        {{ title }}
      </h6>
      <div class="text-muted d-flex flex-column">
        <small>{{ description }}</small>
        <div class="d-flex gap-1 align-items-center">
          <small class="text-body fw-medium">Target:</small>
          <div>
            <small>{{ target }}</small>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column gap-1 mt-2" v-if="grades">
        <div v-for="grade in grades" :key="grade.label" class="d-flex align-items-center gap-2">
          <span :class="`badge border border-${grade.badgeClass} text-${grade.badgeClass}`">{{ grade.label }}</span>
          <div class="text-muted">
            <small>{{ grade.description }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="text-end">
      <h6 class="text-body mb-1">{{ weight }}</h6>
      <small class="text-muted">Weight</small>
    </div>
  </b-list-group-item>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    description: String,
    target: String,
    weight: Number,
    grades: Array,
  },
};
</script>
